/*.section {*/
/*    display: grid;*/
/*    justify-content: center;*/

/*}*/

/*.section__header {*/
/*    font-size: 50px;*/
/*    margin-bottom: 50px;*/
/*    margin-top: 50px;*/
/*}*/

/*.section__list {*/
/*    display: grid;*/
/*    justify-content: center;*/
/*}*/

/*.section__item {*/
/*    display: grid;*/
/*    justify-content: center;*/
/*    margin-bottom: 20px;*/

/*    font-size: 30px;*/
/*    border: 1px solid #333333;*/
/*}*/

.messages__menu {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.messages__header {
    margin-left: 10px;
}

.span {
    margin-right: 10px;
}

.messages-container {
    margin-top: 20px;
}

.select {
    padding: 8px 3px;
    border: 1px solid #cccccc;
}

.section__error {
    padding: 10px;
}

.messages__message {
    margin-top: 10px;
    margin-left: 10px;
    width: 800px;
    border: 1px solid #cccccc;
    background-color: white;
}

.messages__message__item {
    padding: 10px;

    border-top: 1px solid #cccccc;
    border-bottom: 1px solid #cccccc;
}

.messages__users {
    margin-bottom: 20px;
    float: right;
}

.users-select {
    width: 250px;
}

section-select {
    width: 100px;
}

.users-span {
    margin-left: 20px;
}

.last__messages__item{
    margin-bottom: 10px;
    color: grey;
}

.last__messages {
    background-color: #fff;
    border: 2px solid #ccc;
    border-radius: 5px;
    box-shadow: 0px 0px 5px 0px #ccc;
    display: grid;
    padding: 15px;
    gap: 10px;
}

.last__link {
    display: block;
}

last__messages__name {
    color: black;
}