.back-button {
    content:'';
    width:30px;
    height:30px;
    border: 0;
    margin-top: 10px;
    background:url(./images/back.svg) 0 0 no-repeat;
}

.dialog__container {
    display: block;
    max-width: 900px;
    margin-bottom: 80px;
}

.dialog__header {
    margin-bottom: 5px;
}

.dialog__list {
    padding-top: 5px;
    padding-left: 10px;
    background-color: #Fff;
}

.dialog__item {
    background-color: #fff;
    border: 2px solid #ccc;
    border-radius: 5px;
    box-shadow: 0px 0px 5px 0px #ccc;
    display: grid;
    margin: 10px 10px 10px 0;
    padding: 20px;
    gap: 10px;
}

.message-response{
    background-color: #fff;
    /*border: 2px solid #ccc;*/
    border-top: 2px solid #ccc;
    border-radius: 5px;
    display: flex;
    padding: 20px;
    margin-right: 50px;
    position: fixed;
    bottom: 0;
    width: 47%;
}

li:not(:last-child) {
    margin-bottom: 15px;
}

.message-response__btn {
    background: #333;
    border: none;
    border-radius: 3px;
    color: #fff;
    font-size: 16px;
    height: 40px;
    margin-left: 10px;
    margin-right: 20px;
    width: 20%;
}

.message-response__text {
    border: none;
    border-radius: 5px;
    box-shadow: 0px 0px 5px 0px #ccc;
    display: block;
    font-size: 16px;
    padding: 10px;
    width: 75%;
}

.dialog__description {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    font-size: 20px;
}

.dialog__item__time {
    font-size: 10px;
    color: #999999;
}

.dialog__item__name {
    font-weight: bold;
    font-size: 12px;
}


.admin {
    justify-content: flex-end;

}

